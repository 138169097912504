<template>
  <div class="cp-page-inner__wrapper cp-page__404">
    <div class="cp-page-not-found">
      <img src="@/assets/images/404.png" class="cp-page-not-found__img" alt="Страница не найдена" />
      <div class="cp-page-not-found__link-wrapper">
        <router-link :to="{ name: 'home' }" class="cp-page-not-found__link">На главную</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss">
@import '@/assets/scss/templates/page-not-found.scss';
</style>
